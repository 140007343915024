.user-profile {
  width: 100%;
  height: 1080px;
  background-image: url("../../assets/images/bg.png");
  background-size: 100% 100%;
  font-family: "Open Sans";
  header {
    padding: 50px 72px 0px 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .dashboard-wrapper {
    margin: 125px auto;
    width: 80%;

    .dashboard-head {
      display: flex;
      align-items: center;
      height: 65px;
      line-height: 65px;
      p {
        display: inline-block;
        font-size: 48px;
        font-weight: 700;
        margin: 0;
      }

      a svg {
        margin-left: 13px;
        width: 35px;
        height: 35px;
        color: rgb(99, 127, 168);
      }
    }

    .dashboard-cards {
      margin-top: 36px;
      height: 500px;
      display: flex;
      flex-direction: row;
      gap: 28px;

      .mood-journal {
        width: 400px;

        a svg {
          width: 30px;
          height: 30px;
          color: rgb(99, 127, 168);
        }

        .card-body {
          .mood-pic {
            margin: 0 auto;
            width: 200px;
            height: 200px;
            border-radius: 100%;
            // background-color: orange;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
              rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
              align-items: center;
              display: flex;
              justify-content: center;
              img {
                width: 75%;
                height: 75%;
              }
          }

          .mood-state {
            text-align: center;
            margin: 10px auto;
            font-size: 18px;
            color: rgba(152, 174, 202, 1);
          }

          .mood-details {
            height: 130px;
            font-size: 15px;
            color: rgba(0, 0, 0, 0.6);
            // overflow: scroll;
          }
        }
      }

      .mood-tracker {
        width: 997px;

        .card-head {
          .toggle-switch {
            display: flex;
            align-items: center;
            .week-or-month {
              display: inline-block;

              span {
                margin: 0 5px;
              }

              a {
                text-decoration: none;
                color: rgba(0, 0, 0, 0.25);
                font-weight: 600;
              }

              a[disabled] {
                pointer-events: none;
                color: rgba(0, 0, 0, 0.6);
              }
            }
            .switch {
              display: inline-block;
              margin-left: 10px;
            }
          }
        }
      }

      .card-body {
      }

      .card {
        background: #ffffff;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        align-items: center;

        .card-head {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 25px;
          margin-bottom: 20px;
          height: 40px;
          width: 90%;

          p {
            margin: 0;
            display: inline-block;
            font-size: 24px;
            font-weight: 700;
            color: rgba(0, 0, 0, 0.6);
          }
        }

        .card-body {
          width: 90%;
        }
      }
    }
  }
}

.mood-modal-frame {
  display: flex;
  flex-direction: row;
  .mood-modal-card {
    width: 18%;
    margin-right:2%;
    margin-bottom: 10px;
    padding-top: 20px;
    padding-bottom: 10px;
    text-align: center;
    img {
      margin-bottom: 10px;
    }

  }
  .mood-modal-card:hover,.mood-modal-card-selected {
    box-shadow: inset 0px 2px 10px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
  }

}
.mood-modal-text {
  textarea {
    width: 100%;
    height:130px;
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: inset 0px 2px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px


  }
}