.weekly-tracker-wrapper {
  display: flex;
  flex-direction: row;
  gap: 50px;
  height: 270px;
  align-items: center;
  align-content: center;
  .mood-lineChart {
    //background-color: pink;
    height: 100%;
    width: 400px;
  }

  .mood-pieChart {
    // background-color: orange;

    width: 450px;
  }
}
