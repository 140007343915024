.register {
  width: 100%;
  height: 1300px;
  background-image: url("../../assets/images/bg.png");
  background-size: 100% 100%;
  font-family: "Open Sans";

  .register-body {
    width: 475px;
    height: 553px;
    margin: 191px auto;

    .form-title {
      margin-bottom: 10px;
      font-weight: bold;
      font-size: 48px;
      text-align: center;
    }

    .form-subtitle {
      margin-bottom: 20px;
      text-align: center;
      font-weight: normal;
      font-size: 19px;
      color: rgba(0, 0, 0, 0.4);
    }

    .register-form {
      .register-antd-form {
        label {
          margin-bottom: 6px;
          font-size: 18px;
        }

        a {
          text-decoration: none;
        }

        .required:after {
          color: #e32;
          content: " *";
          display: inline;
        }

        .ant-input {
          font-size: 20px;
          padding-left: 20px;
          height: 60px;
          background: #ffffff;
          box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
          border-radius: 20px;
        }
        .register-email {
        }
        .register-password {
        }

        .register-repeat-pw {
        }

        /* .alias {
          label {
            display: flex;
            align-items: center;
            .alias-explain {
              margin-left: 10px;
            }
            .alias-explain:hover {
              margin-left: 10px;
            }
          }
        } */

        .alias {
          .ant-form-vertical,
          .ant-form-item-label {
            padding: 0px;
          }
        }

        .register-subscribe {
          display: flex;
          align-items: center;
          font-size: 18px;
          font-weight: normal;

          .subscribe-left {
            display: inline-block;
            width: 30px;
            margin-right: 19px;
            .subscribe-checkbox {
              vertical-align: middle;

              margin-right: 14px;
              width: 30px;
              height: 30px;
              background: #ffffff;
              border: 3px solid #98aeca;
              box-sizing: border-box;
              border-radius: 10px;
            }
          }
          .subscribe-right {
            display: inline-block;
            width: 426px;
            label {
              margin-bottom: 0px;
            }
          }
        }

        .register-agree {
          vertical-align: middle;
          font-size: 18px;
          font-weight: normal;
          line-height: 50px;
          margin-bottom: 28px;

          .agree-left {
            display: inline-block;
            width: 30px;
            margin-right: 19px;
            .agree-checkbox {
              vertical-align: middle;
              margin-right: 14px;
              width: 30px;
              height: 30px;
              background: #ffffff;
              border: 3px solid #98aeca;
              box-sizing: border-box;
              border-radius: 10px;
            }
          }

          .agree-right {
            display: inline-block;
            width: 426px;
          }
        }

        .register-signup {
          margin-bottom: 27px;
          .register-form-button {
            font-weight: bold;
            font-size: 24px;
            width: 475px;
            height: 60px;
            background: #98aeca;
            box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
            border-radius: 20px;
          }
        }

        .to-sign-in-wrap {
          margin-top: 29px;
          margin-bottom: 218px;
          font-weight: normal;
          font-size: 18px;
          .to-sign-in {
            font-weight: 600;
            color: #5d7fac;
            margin-bottom: 42px;
          }
        }
      }
    }
  }
}
