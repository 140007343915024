.login {
  width: 100%;
  height: 1080px;
  background-image: url("../../assets/images/bg.png");
  background-size: 100% 100%;
  font-family: "Open Sans";

  .login-body {
    width: 475px;
    height: 553px;
    margin: 191px auto;

    .form-title {
      margin-bottom: 10px;
      font-weight: bold;
      font-size: 48px;
      text-align: center;
    }

    .form-subtitle {
      margin-bottom: 20px;
      text-align: center;
      font-weight: normal;
      font-size: 18px;
      color: rgba(0, 0, 0, 0.4);
    }

    .login-form {
      .login-antd-form {
        label {
          margin-bottom: 6px;
          font-size: 18px;
        }

        a {
          text-decoration: none;
        }

        .required:after {
          color: #e32;
          content: " *";
          display: inline;
        }

        .ant-input {
          font-size: 20px;
          padding-left: 20px;
          height: 60px;
          background: #ffffff;
          box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
          border-radius: 20px;
        }
        .login-email {
        }
        .login-password {
        }

        .login-remember {
          margin-top: 27px;
          margin-bottom: 61px;
          font-size: 18px;
          font-weight: normal;
          height: 50px;

          .remember-label {
            .remember-checkbox {
              vertical-align: middle;
              margin-right: 14px;
              width: 30px;
              height: 30px;
              background: #ffffff;
              border: 3px solid #98aeca;
              box-sizing: border-box;
              border-radius: 10px;
            }
          }

          .login-form-forgot {
            margin-left: 153px;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            text-align: right;
            color: #5d7fac;
          }
        }

        .login-submit {
          .login-form-button {
            font-weight: bold;
            font-size: 24px;
            width: 475px;
            height: 60px;
            background: #98aeca;
            box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
            border-radius: 20px;
          }
        }

        .to-register {
          margin-top: 29px;
          margin-bottom: 218px;
          font-weight: normal;
          font-size: 18px;
          span {
            .create-account {
              font-weight: 600;
              color: #5d7fac;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}
