.header {
    width: 100%;    
    .header-left {

        .logo {
            width: 296px;
            height: 49px;
            margin-top: 50px;
            margin-left: 50px;
        }
    }
}
